/**********************************************
********* Additional Helper Classes ***********
***********************************************/

/*
The goal of these classes is to mimic and supplement
the helper classes provided by Canvas which can be found
here: https://designsystems.prod.target.com/canvas/styles/helper-classes
*/

/* 
Use this directly on an <EnterpriseIcon /> component
to center the icon within a <Grid.Item />. To work
properly, the icon should be the _only_ element in
the <Grid.Item />.
*/
.hc-icon-center {
  display: block;
  margin: auto;
}