.navbar-container {
    width: 100%;
    background-color: var(--mpm-primary-color);
    height: auto;
    display: grid;
    padding: var(--canvas-spacing--md) var(--canvas-spacing--lg);
    grid-template-columns: var(--canvas-spacing--4x) 1fr var(--canvas-spacing--4x);
    align-content: center;
    align-items: center;
}

.navbar-container-right {
    column-gap: 10px;
    display: flex;
}

.navbar-heading {
    text-align: center;
}

.kpi-heading, .location-heading {
    color: white;
}

.navbar-icon {
    color: white;
}

.navbar-alerts {
    position: relative;
}
