

.custom-datepicker-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.progress-bar-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.weather-content {
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.weather-icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.key-statistics-heading {
  margin-top: 20px;
}

.key-statistics-grid {
  padding: 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  text-align: left;
}
