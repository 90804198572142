.location-search-navbar-container {
    width: 100%;
    background-color: var(--mpm-primary-color);
    height: 80px;
    display: grid;
    padding: var(--canvas-spacing--md) var(--canvas-spacing--lg);
    grid-template-columns: var(--canvas-spacing--xl) 1fr var(--canvas-spacing--xl);
    align-content: center;
}

H3.location-search-navbar-heading {
    margin: 0;
    font-family: 'Helvetica For Target', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: var(--canvas-theme-color--text);
    text-align: center;
}

.location-search-content-container {
    padding: 181px var(--canvas-spacing--lg) var(--canvas-spacing--lg);
}

.location-search-fixed-content {
    position: fixed;
    width: 100%;
    z-index: var(--canvas-z-index--fixedElement);
    background-color: var(--canvas-background-color--app);
}

.location-search-fixed-input {
    margin: var(--canvas-spacing--lg);
    margin-bottom: 0;
}

#location-search-input-clear-button {
    color: var(--canvas-font-color--text);
    position: absolute;
    top: 8px;
    right: 8px;
}

#location-search-input {
    height: 48px;
    font-size: var(--canvas-font-size--md);
}

.location-search-input {
    width: 100%;
}

.location-search-input-container {
    display: flex;
    position: relative;
}

.location-search-result {
    padding: var(--canvas-spacing--xs) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location-search-result-short-name, .location-search-result-distance-away {
    font-size: var(--canvas-font-size--md);
}

.location-search-input p {
    display: none;
}

.nearby-locations-heading {
    font-size: var(--canvas-font-size--sm);
    font-weight: 500;
}
