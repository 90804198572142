.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .splash-image {
    border-radius: 10%;
  }
  