.floating-action-button {
    background-color: var(--canvas-background-color--card);
    border: none;
    box-shadow: var(--canvas-elevation--3);
    font-size: var(--canvas-font-size--lg);
    color: var(--canvas-font-color--default);
    padding: var(--canvas-spacing--md);
    border-radius: var(--canvas-spacing--md);
    display: flex;
    gap: var(--canvas-spacing--xs)
}

.floating-action-button:active {
    box-shadow: var(--canvas-elevation--1)
}

.floating-action-buttons-container {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: var(--canvas-spacing--lg);
    margin-right: var(--canvas-spacing--lg);
    margin-bottom: var(--canvas-spacing--2x);
}
