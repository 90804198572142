.navbar-container {
    width: 100%;
    background-color: var(--mpm-primary-color);
    height: auto;
    display: grid;
    padding: var(--canvas-spacing--md) var(--canvas-spacing--lg);
    grid-template-columns: var(--canvas-spacing--4x) 1fr var(--canvas-spacing--4x);
    align-content: center;
    align-items: center;

    &.adjust-pb {
        padding-bottom: var(--canvas-spacing--none);
    }
}

.navbar-container-right {
    column-gap: 10px;
    display: flex;
}

.navbar-alerts {
    position: relative;
}

.navbar-alerts .unread-count {
    position: absolute;
    top: -8px;
    height: 28px;
    width: 28px;
}

.navbar-alerts .unread-count:before {
    background-color: var(--canvas-background-color--error);
}

.navbar-heading {
    text-align: center;
}

.kpi-heading, .location-heading {
    color: white;
}

.navbar-icon {
    color: white;
}

.navbar-title-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.main-content-container {
    padding: var(--canvas-spacing--lg);
}

.bottom-nav-container {
    position: absolute;
    bottom: 0;
    border-top: 1px solid var(--canvas-font-color--default);
    width: 100%;
    height: 80px;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    background-color: white;
}

.botton-nav-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottom-nav-container > .bottom-nav-button {
    border: none;
    background-color: inherit;
    color: var(--canvas-font-color--default);
}

.bottom-nav-button-label {
    font-family: 'Helvetica For Target', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: var(--canvas-font-color--default);
    text-align: center;
}

#metrics-button.bottom-nav-active, #alerts-button.bottom-nav-active, .bottom-nav-button-label.bottom-nav-active {
    color: red;
}

#metrics-button, #alerts-button {
    color: var(--canvas-font-color--default);
}
